/* Styles the content within a WYSIWYG editor */
.tiptap-editor h2 {
  font-family: 'Rules';
  font-weight: 500;
  font-size: 1.5rem;
}

.tiptap-editor h3 {
  font-family: 'Rules';
  font-weight: 500;
  font-size: 1.25rem;
}

.tiptap-editor p {
  font-family: 'Rules';
  font-weight: 400;
  font-size: 1rem;
}

.tiptap-editor {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1.25rem;
}
