@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

:root {
  color-scheme: dark;
  --tw-shadow-color: rgb(255, 255, 255, 0.5);
}

html,
body {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#__next {
  min-height: 100%;
  width: 100%;
}

#__next {
  display: flex;
}

a {
  color: inherit;
  text-decoration: none;
}

#Canvas--gradient {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.Text--shadowed {
  text-shadow: 0 1px 1px rgb(0 0 0);
}

.Text--Hunt-glow {
  font-weight: 400;
  color: rgb(203, 212, 237);
  -webkit-text-stroke: 1px rgb(75, 144, 255);
  paint-order: stroke fill;
  text-shadow: 2px 1px 2px rgb(20, 79, 255), 0px 0px 3px rgb(59, 147, 255),
    0px 0px 3px rgb(137, 221, 244);
}

.Text--Hunt-glow-user {
  mix-blend-mode: hard-light;
  font-weight: 400;
  color: rgb(245, 253, 255);
  -webkit-text-stroke: 1px rgb(211, 211, 211);
  paint-order: stroke fill;
  text-shadow: 2px 1px 2px rgb(41, 163, 250), 0px 0px 3px rgb(0, 106, 254),
    0px 0px 3px rgb(137, 221, 244);
}

.Text--TrebuchetMS {
  font-family: 'Trebuchet MS';
}

.Text--subtitle {
  font-family: 'Trebuchet MS';
  color: #ffaa00;
  -webkit-text-stroke: 1px black;
  text-shadow: 0px 3px 1px rgb(0 0 0);
}

.Button--primary {
  @apply bg-gray-200 text-gray-900;
}

.Button--primary:enabled {
  @apply hover:bg-white hover:text-black;
}

.Button--secondary {
  @apply bg-gray-800 text-gray-100;
}

.Button--secondary:enabled {
  @apply hover:bg-gray-700 hover:text-white;
}

.Button--primary:disabled,
.Button--secondary:disabled {
  @apply cursor-not-allowed opacity-80;
}

.Background--rainbow {
  background: linear-gradient(
    110.78deg,
    rgb(118, 230, 80) -1.13%,
    rgb(249, 214, 73) 15.22%,
    rgb(240, 142, 53) 32.09%,
    rgb(236, 81, 87) 48.96%,
    rgb(255, 24, 189) 67.94%,
    rgb(26, 75, 255) 85.34%,
    rgb(98, 216, 249) 99.57%
  );
}

.Toastify__progress-bar--success {
  @apply bg-green-500;
}

.Toastify__progress-bar--info {
  @apply bg-blue-500;
}

.Toastify__progress-bar--warn,
.Toastify__progress-bar--warning {
  @apply bg-amber-500;
}

.Toastify__progress-bar--error {
  @apply bg-red-500;
}

.Toastify__toast {
  @apply backdrop-blur-md;
  @apply bg-white bg-opacity-10;
}

.VimeoContainer {
  position: relative;
  /* Handle aspect ratio: https://stackoverflow.com/questions/42858199/vimeo-full-width */
  padding-bottom: 56.25%;
  overflow: hidden;
}

.ShowDetailsContainer {
  min-width: 480px;
}

@layer base {
  @font-face {
    font-family: 'Nokia';
    src: url('/fonts/Nokia-3410.ttf');
  }
  @font-face {
    font-family: 'Lomo Wall Pixel';
    src: url('/fonts/lomowallpixel-lt-std-50.otf');
  }
  @font-face {
    font-family: 'Rules';
    font-weight: 400;
    src: url(/fonts/Rules/Rules-Regular.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Rules';
    font-weight: 500;
    src: url(/fonts/Rules/Rules-Medium.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Rules';
    font-weight: 700;
    src: url(/fonts/Rules/Rules-Bold.woff2) format('woff2');
  }
  @font-face {
    font-family: 'Anonymous Pro';
    font-weight: 700;
    src: url(/fonts/AnonymousPro/AnonymousPro-Bold.ttf) format('ttf');
  }
}

@media only screen and (max-width: 600px) {
  .ShowDetailsContainer {
    min-width: auto;
  }
}

@layer components {
  /* Eventually we will move as many of these as we can to the base layer, but for now we have to migrate
   * existing pages to the design system first */
  .shib-title {
    @apply font-rules text-3xl font-medium leading-snug sm:text-6xl;
  }

  .shib-h1 {
    @apply font-rules text-2xl font-medium leading-8 sm:text-5xl sm:leading-normal;
  }

  .shib-h2 {
    @apply font-rules text-xl font-medium leading-7 sm:text-4xl;
  }

  .shib-h3 {
    @apply font-rules text-lg font-medium leading-6 sm:text-3xl sm:leading-9;
  }

  .shib-subtitle {
    @apply font-rules text-base font-medium leading-6 sm:text-2xl;
  }

  .shib-body {
    @apply font-rules text-sm font-medium leading-5 sm:text-xl;
  }

  .shib-text-description {
    @apply font-rules text-sm font-medium leading-5 sm:text-base;
  }

  .shib-small-text {
    @apply font-rules text-xs font-medium leading-4 sm:text-sm sm:leading-5;
  }

  .text-lg-button {
    @apply font-rules text-sm font-bold leading-5 sm:text-base;
  }

  .text-sm-button {
    @apply font-rules text-xs font-bold leading-4 sm:text-sm;
  }
}

/* react-slick stacks the slides on top of one another and that messes with clickable elements.
 * This bit of css ensures that the active slide is the one on top.
 */
.slick-slide.slick-active {
  z-index: 10;
}
