@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-clouds {
  0% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
    opacity: 0.3;
  }

  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-kanji {
  0% {
    transform: scale(1);
    translate: 0;
    opacity: 1;
  }

  50% {
    transform: scale(0.95);
    opacity: 0.5;
    translate: 0;
  }

  100% {
    transform: scale(1);
    translate: 0;
    opacity: 1;
  }
}

.animate-pulse-slow {
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 9s;
}

.animate-pulse-clouds {
  animation-name: pulse-clouds;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 12s;
}

.animate-pulse-kanji {
  animation-name: pulse-kanji;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 2s;
  animation-duration: 12s;
}
